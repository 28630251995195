<template>
  <div>
    <div
      v-for="(shop, key) in allDetail"
      :key="key"

      v-show="shop.total.total_price > 0"
    >

      <!--
        shop.list = 摘要のリストがthis.printDataPerPage件以上の場合、次のページに移動する
      -->
      <div
        class="print-paper portrait"
        v-for="page in numberOfPages(shop.list.length, printDataPerPage)"
        :key="`page-${page}`"
      >
        <!--デバッグ用 インデックス番号を表示-->
        <!--<p v-if="shop.total.total_price > 0">{{ key }}</p>-->
        <div id="print-inner">
          <div id="print-meta">
            <p class="print-ttl">請求書</p>

            <p class="print-period">
              {{ shop.store.start_date }} ～ {{ shop.store.end_date }}
            </p>

            <div class="print-meta__right">
              <div class="print-time">
                {{ shop.store.now_date }} <span v-show="checkMonth(shop.store.start_date)">現在</span>
                <br />
                <!--1/1頁-->
                {{ page }}/{{ numberOfPages(shop.list.length, printDataPerPage) }}頁

                <br />
                <br />
              </div>
              <print-signature />
            </div>

            <p class="print-address">
              〒{{ shop.store.zipcode }}<br />
              {{ shop.store.address }}<br />
              {{ shop.store.yago }} 御中
            </p>
          </div>

          <printInvoiceList
            :list="getPageItems(shop.list, page, printDataPerPage)"
          />

          <ul class="list invoice no-shrink" v-if="page == numberOfPages(shop.list.length, printDataPerPage)">
            <li class="invoice-title">TOTAL</li>
            <li class="list-item has-border-top">
              <div class="list-item__data invoice-name">8% 対象額</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.eight.total_price | comma }}
              </div>

              <div class="list-item__data invoice-name">8% 消費税</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.eight.tax_price | comma }}
              </div>

              <div class="list-item__data invoice-name">合計</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.eight.total_tax_price | comma }}
              </div>
            </li>
            <li class="list-item">
              <div class="list-item__data invoice-name">10% 対象額</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.ten.total_price | comma }}
              </div>

              <div class="list-item__data invoice-name">10% 消費税</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.ten.tax_price | comma }}
              </div>

              <div class="list-item__data invoice-name">合計</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.ten.total_tax_price | comma }}
              </div>
            </li>
            <li class="list-item total">
              <div class="list-item__data invoice-name">合計</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.total_price | comma }}
              </div>

              <div class="list-item__data invoice-name">消費税合計</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.tax_price | comma }}
              </div>

              <div class="list-item__data invoice-name">総額</div>
              <div class="list-item__data invoice-data">
                {{ shop.total.total_tax_price | comma }}
              </div>
            </li>

          </ul>

        </div>

      </div><!-- print-paper -->

    </div>
  </div>
</template>

<script>
import Global from "@/components/mixins/Global.vue";
import printInvoiceList from "@/components/iseya/printInvoiceList.vue";
import printSignature from "@/components/iseya/printSignature.vue";
export default {
  name: "printInvoice",
  props: {
    allDetail: Array
  },
  data () {
    return {
      thisMonth: false,
      printDataPerPage: 31
    }
  },
  components: {
    printInvoiceList,
    printSignature
  },
  mixins: [Global],
  mounted() {
  },
  methods: {
    checkMonth(startDate) {
      //allDetail[0].store.start_dateが今月かどうかを判定する
      //今月ならthisMonthをtrueにする
      //startDateは"2023年11月1日"の形式
      let now = new Date();
      let thisMonth = now.getMonth() + 1;
      let thisYear = now.getFullYear();
      let startMonth = startDate.slice(5, 7);
      let startYear = startDate.slice(0, 4);
      if (thisMonth == startMonth && thisYear == startYear) {
        this.thisMonth = true;
      }

      console.log(thisMonth);
      console.log(startMonth);
      return this.thisMonth;
    },
    numberOfPages(listLength, dataPerPage) {
      // 印刷枚数を計算する
      return Math.ceil(listLength / dataPerPage);
    },
    getPageItems(list, page, dataPerPage) {
      // ページに表示するデータを取得する
      const start = (page - 1) * dataPerPage;
      const end = start + dataPerPage;
      return list.slice(start, end);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 1282px;
  margin: 0 auto;
  border-left: solid 1px variables.$bg-gray-2;
  margin-bottom: 30px;
}

.list li {
  width: 100%;
  position: relative;
  &.has-border-top {
    border-top: solid 1px variables.$txt-default;
  }
  &.total {
    background: variables.$bg-gray-1;
    &.visible {
      display: flex;
    }
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
  border-top: solid 1px variables.$bg-gray-2;
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  position: relative;
  a {
    color: #000;
    text-decoration: underline;
    transition: .3s ease-in-out;
    &:hover {
      opacity: .6
    }
  }
  &.total {
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }

  .delete-btn {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 0;
    z-index: 5;
    transition: opacity 0.3s ease-in-out;

    .delete {
      cursor: pointer;
    }
  }

  &:hover .delete-btn {
    opacity: 1;
    pointer-events: all;
  }
}
.list-item__inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.number {
    justify-content: flex-end;
    input {
      text-align: right;
    }
  }
  &.negative {
    color: variables.$negative;
  }

  input {
    width: calc(100% - 10px);
    margin-right: 10px;
    border: solid 1px variables.$bg-gray-2;
    height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
  }
}

.col-name {
  width: 450px;
}

.col-sales {
  width: 130px;
}

.col-taxin {
  width: 140px;
}

.col-note {
  width: 300px;
}

.list.invoice {
  border-bottom: solid 1px variables.$txt-default;
  li {
    height: 50px;
  }
}
.invoice-title {
  max-width: calc(100% / 6);
  max-height: 40px;
  background: variables.$txt-default;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-name,
.invoice-data {
  width: calc(100% / 6);
  justify-content: flex-end;
}

.invoice-name {
  background: variables.$bg-gray-1;
  border-left: solid 1px variables.$txt-default;
}

.invoice-data {
  &:last-of-type {
    border-right: solid 1px variables.$txt-default;
  }
}
.print-paper {
  //width: 668px;
  //height: 945px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  margin-left: auto;
  margin-right: auto;
  .list {
    width: 606px;
    //transform: scale(0.69);
    transform-origin: top left;
    font-size: 1rem;
    &.invoice {
      margin-top: 30px;
    }

  }

    .list li {
      height: 23px;
    }

  .list-item {
    height: 30px;
  }
  .col-name {
    width: 155px;
  }
  .col-sales,
  .col-taxin {
    width: 90px;
  }
  .col-note {
    width: 90px;
  }
}

#print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#print-meta {
  margin-bottom: 30px;
}

.print-meta__right {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
}

.print-time {
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 60px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}
</style>
