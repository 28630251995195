<template>


  <ul class="list no-shrink">
    <li class="list-head">
      <div class="list-head__item col-name">摘要</div>
      <div class="list-head__item col-tax">区分</div>
      <div class="list-head__item col-sales">税抜価格</div>
      <div class="list-head__item col-sales">消費税</div>
      <div class="list-head__item col-taxin">税込価格</div>
      <div class="list-head__item col-note">備考</div>
    </li>
    <div>
      <li class="list-item" v-for="(row, key) in listItems" :key="key">
        <div class="list-item__data col-name">{{ row.name }}</div>
        <div class="list-item__data col-tax number">
          {{ row.tax_class }}%
        </div>
        <div
          class="list-item__data col-sales number"
          :class="{ negative: row.total_price < 0 }"
        >
          {{ row.total_price | comma }}
        </div>
        <div
          class="list-item__data col-sales number"
          :class="{ negative: row.total_price < 0 }"
        >
          {{ row.tax_price | comma }}
        </div>
        <div
          class="list-item__data col-taxin number"
          :class="{ negative: row.total_price < 0 }"
        >
          {{ row.total_tax_price | comma }}
        </div>
        <div class="list-item__data col-note">{{ row.note }}</div>
      </li>

    </div>
  </ul>


</template>

<script>
import Global from "@/components/mixins/Global.vue";
export default {
  name: "printInvoice",
  props: {
    list: Array
  },
  mixins: [Global],
  data: function() {
    return {
      listItems: this.list
    }
  },
  created() {
    this.listItems = this.list.filter(item => {
      //return item.total_price > 0;
      //item.total_priceが1以上、もしくはitem.total_priceが-1以下の場合
      return item.total_price > 0 || item.total_price < -1;
    });

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 1282px;
  margin: 0 auto;
  border-left: solid 1px variables.$bg-gray-2;
  margin-bottom: 30px;
}

.list li {
  width: 100%;
  position: relative;
  &.has-border-top {
    border-top: solid 1px variables.$txt-default;
  }
  &.total {
    background: variables.$bg-gray-1;
    &.visible {
      display: flex;
    }
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
  border-top: solid 1px variables.$bg-gray-2;
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  position: relative;
  a {
    color: #000;
    text-decoration: underline;
    transition: .3s ease-in-out;
    &:hover {
      opacity: .6
    }
  }
  &.total {
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }

  .delete-btn {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 0;
    z-index: 5;
    transition: opacity 0.3s ease-in-out;

    .delete {
      cursor: pointer;
    }
  }

  &:hover .delete-btn {
    opacity: 1;
    pointer-events: all;
  }
}
.list-item__inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.number {
    justify-content: flex-end;
    input {
      text-align: right;
    }
  }
  &.negative {
    color: variables.$negative;
  }

  input {
    width: calc(100% - 10px);
    margin-right: 10px;
    border: solid 1px variables.$bg-gray-2;
    height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
  }
}

.col-name {
  width: 450px;
}

.col-sales {
  width: 130px;
}

.col-taxin {
  width: 140px;
}

.col-note {
  width: 300px;
}

.list.invoice {
  border-bottom: solid 1px variables.$txt-default;
  li {
    height: 50px;
  }
}
.invoice-title {
  max-width: calc(100% / 6);
  max-height: 40px;
  background: variables.$txt-default;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-name,
.invoice-data {
  width: calc(100% / 6);
  justify-content: flex-end;
}

.invoice-name {
  background: variables.$bg-gray-1;
  border-left: solid 1px variables.$txt-default;
}

.invoice-data {
  &:last-of-type {
    border-right: solid 1px variables.$txt-default;
  }
}
.print-paper {
  //width: 668px;
  //height: 945px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  margin-left: auto;
  margin-right: auto;
  .list {
    width: 606px;
    //transform: scale(0.69);
    transform-origin: top left;
    font-size: 1rem;
    &.invoice {
      margin-top: 30px;
    }

  }

    .list li {
      height: 23px;
    }

  .list-item {
    height: 30px;
  }
  .col-name {
    width: 185px;
  }
  .col-sales,
  .col-taxin {
    width: 90px;
  }
  .col-tax {
    width: 60px;
  }
  .col-note {
    width: 90px;
  }
}

#print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#print-meta {
  margin-bottom: 30px;
}

.print-meta__right {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
}

.print-time {
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 60px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}
</style>
